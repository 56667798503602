import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Layout from '../components/Layout'

export default props => {
  const { data } = props
  const { edges: companies } = data.allSanityExhibitors
  const { sanityCategory: category } = data

  return (
    <Layout>
      <SEO title={`${category.title} | Agility Prime Virtual Tradeshow`} />
      <Hero title={`Category: ${category.title}`} />
      <div className="container">
        <section className="exhibitors">
          {notNilOrEmpty(companies) &&
            mapIndexed(({ node: company }, index) => {
              return (
                <div>
                  <AniLink
                    cover
                    duration={1}
                    className="image"
                    to={`/exhibitor/${company.slug.current}`}
                    key={index}
                    bg="#000"
                  >
                    <div className={`exhibitors__single animated`}>
                      <div className="company_image">
                        <Img fluid={company.company_img.asset.fluid} />
                      </div>
                    </div>
                    <p className="company_title text--center">
                      {company.title}
                    </p>
                  </AniLink>
                </div>
              )
            })(
              R.compose(R.sort(R.ascend(R.path(['node', 'title']))))(companies)
            )}
        </section>
      </div>
    </Layout>
  )
}

export const catQuery = graphql`
  query CatTemplateQuery($_id: String!) {
    sanityCategory(_id: { eq: $_id }) {
      title
    }
    allSanityExhibitors(
      filter: { categories: { elemMatch: { _id: { eq: $_id } } } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          company_img {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          categories {
            slug {
              current
            }
            title
          }
        }
      }
    }
  }
`
